import gql from 'graphql-tag'


const listResponse = `
  id type docType options
  contactId contact {id type code name}
  parentId parent {id type name}
`

export const LIST_DOC_CONFIG_EMPLOYEE = (templateType) => gql`query LIST_DOC_CONFIG_EMPLOYEE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigEmployee (docType: $docType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType options parentId
  contactId contact {id type code name}
`

export const DETAIL_DOC_CONFIG_EMPLOYEE = (templateType) => gql`query DETAIL_DOC_CONFIG_EMPLOYEE ($docType: String!, $docConfigId: Int!) {
  item: detailDoc${templateType}DocConfigEmployee (docType: $docType, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_DOC_CONFIG_EMPLOYEE = (templateType) => gql`mutation CREATE_DOC_CONFIG_EMPLOYEE ($docType: String!, $docConfigId: Int, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfigEmployee (docType: $docType, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_DOC_CONFIG_EMPLOYEE = (templateType) => gql`mutation DESTROY_DOC_CONFIG_EMPLOYEE ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfigEmployee (docType: $docType, docConfigId: $docConfigId) {id}
}`
