import gql from 'graphql-tag'


export const LIST_ORDER_SOCIAL_SECURITY = (templateType) => gql`query LIST_ORDER_SOCIAL_SECURITY ($docType: String!, $docId: Int!, $q: FilterInput) {
  items: listDoc${templateType}OrderSocialSecurity (docType: $docType, docId: $docId, q: $q) {
    id type name
  }
}`

export const CREATE_ORDER_SOCIAL_SECURITY = (templateType) => gql`mutation CREATE_ORDER_SOCIAL_SECURITY ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  item: createDoc${templateType}OrderSocialSecurity (docType: $docType, docId: $docId, input: $input) {id}
}`

export const DETAIL_ORDER_SOCIAL_SECURITY_PRICE = (templateType) => gql`query DETAIL_ORDER_SOCIAL_SECURITY_PRICE ($docType: String!, $docId: Int!, $docConfigId: Int!) {
  item: detailDoc${templateType}OrderSocialSecurityPrice (docType: $docType, docId: $docId, docConfigId: $docConfigId) {
    qty price
  }
}`
