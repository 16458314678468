import gql from 'graphql-tag'


const discountResponse = `
  id parentId childId options
  childId child {
    id type name options
  }
`

export const LIST_DOC_CONFIG_EMPLOYEE_DISCOUNT = (templateType) => gql`query LIST_DOC_CONFIG_EMPLOYEE_DISCOUNT ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}DocConfigEmployeeDiscount (docType: $docType, parentId: $parentId) {${discountResponse}}
}`

export const CREATE_DOC_CONFIG_EMPLOYEE_DISCOUNT = (templateType) => gql`mutation CREATE_DOC_CONFIG_EMPLOYEE_DISCOUNT ($docType: String!, $parentId: Int!, $discountId: Int!) {
  item: createDoc${templateType}DocConfigEmployeeDiscount (docType: $docType, parentId: $parentId, discountId: $discountId) {${discountResponse}}
}`

export const UPDATE_DOC_CONFIG_EMPLOYEE_DISCOUNT = (templateType) => gql`mutation UPDATE_DOC_CONFIG_EMPLOYEE_DISCOUNT ($docType: String!, $parentId: Int!, $discountId: Int!, $input: DocConfigInput!) {
  item: updateDoc${templateType}DocConfigEmployeeDiscount (docType: $docType, parentId: $parentId, discountId: $discountId, input: $input) {${discountResponse}}
}`

export const DESTROY_DOC_CONFIG_EMPLOYEE_DISCOUNT = (templateType) => gql`mutation DESTROY_DOC_CONFIG_EMPLOYEE_DISCOUNT ($docType: String!, $parentId: Int!, $discountId: Int!) {
  item: destroyDoc${templateType}DocConfigEmployeeDiscount (docType: $docType, parentId: $parentId, discountId: $discountId) {id}
}`
