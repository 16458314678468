import gql from 'graphql-tag'


const listResponse = `
  id type docType name options
  accountId account {id code name}
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
`

export const LIST_DOC_CONFIG_TAX = (templateType) => gql`query LIST_DOC_CONFIG_TAX ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigTax (docType: $docType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType name options
  accountId account {id code name}
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
`

export const DETAIL_DOC_CONFIG_TAX = (templateType) => gql`query DETAIL_DOC_CONFIG_TAX ($docType: String!, $docConfigId: Int!) {
  item: detailDoc${templateType}DocConfigTax (docType: $docType, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_DOC_CONFIG_TAX = (templateType) => gql`mutation CREATE_DOC_CONFIG_TAX ($docType: String!, $docConfigId: Int, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfigTax (docType: $docType, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_DOC_CONFIG_TAX = (templateType) => gql`mutation DESTROY_DOC_CONFIG_TAX ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfigTax (docType: $docType, docConfigId: $docConfigId) {id}
}`
