import gql from 'graphql-tag'


export const LIST_DOC_CONFIG_EMPLOYEE_PROFILE = (templateType) => gql`query LIST_DOC_CONFIG_EMPLOYEE_PROFILE ($docType: String!, $parentId: Int!, $types: [String!]) {
  items: listDoc${templateType}DocConfigEmployeeProfile (docType: $docType, parentId: $parentId, types: $types) {
    id type docType
    options parentId
    accountId account {id code name}
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
  }
}`

export const LIST_DOC_CONFIG_EMPLOYEE_PROFILE_OVERWRITE = (templateType) => gql`query LIST_DOC_CONFIG_EMPLOYEE_PROFILE_OVERWRITE ($docType: String!, $parentId: Int!, $types: [String!]) {
  junctions: listDoc${templateType}DocConfigEmployeeProfileOverwrite (docType: $docType, parentId: $parentId, types: $types) {
    id parentId childId options
  }
}`

export const CREATE_DOC_CONFIG_EMPLOYEE_PROFILE = (templateType) => gql`mutation CREATE_DOC_CONFIG_EMPLOYEE_PROFILE ($docType: String!, $parentId: Int!, $profileId: Int!, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfigEmployeeProfile (docType: $docType, parentId: $parentId, profileId: $profileId, input: $input) {id}
}`

export const DESTROY_DOC_CONFIG_EMPLOYEE_PROFILE = (templateType) => gql`mutation DESTROY_DOC_CONFIG_EMPLOYEE_PROFILE ($docType: String!, $parentId: Int!, $profileId: Int!) {
  item: destroyDoc${templateType}DocConfigEmployeeProfile (docType: $docType, parentId: $parentId, profileId: $profileId) {id}
}`
