import gql from 'graphql-tag'


const taxResponse = `
  id parentId childId options
  childId child {
    id type name options
    accountId account {id code name}
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
  }
`

export const LIST_DOC_CONFIG_EMPLOYEE_TAX = (templateType) => gql`query LIST_DOC_CONFIG_EMPLOYEE_TAX ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}DocConfigEmployeeTax (docType: $docType, parentId: $parentId) {${taxResponse}}
}`
