import gql from 'graphql-tag'


const listResponse = `
  id type docType name
`

export const LIST_DOC_CONFIG_JOB_DESCRIPTION = (templateType) => gql`query LIST_DOC_CONFIG_JOB_DESCRIPTION ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigJobDescription (docType: $docType, q: $q) {${listResponse}}
}`

const childResponse = `
  id type name
`

const detailResponse = `
  id type docType name
  children {${childResponse}}
`

export const DETAIL_DOC_CONFIG_JOB_DESCRIPTION = (templateType) => gql`query DETAIL_DOC_CONFIG_JOB_DESCRIPTION ($docType: String!, $docConfigId: Int!) {
  item: detailDoc${templateType}DocConfigJobDescription (docType: $docType, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_DOC_CONFIG_JOB_DESCRIPTION = (templateType) => gql`mutation CREATE_DOC_CONFIG_JOB_DESCRIPTION ($docType: String!, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfigJobDescription (docType: $docType, input: $input) {${detailResponse}}
}`

export const UPDATE_DOC_CONFIG_JOB_DESCRIPTION = (templateType) => gql`mutation UPDATE_DOC_CONFIG_JOB_DESCRIPTION ($docType: String!, $docConfigId: Int!, $input: DocConfigInput!) {
  item: updateDoc${templateType}DocConfigJobDescription (docType: $docType, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_DOC_CONFIG_JOB_DESCRIPTION = (templateType) => gql`mutation DESTROY_DOC_CONFIG_JOB_DESCRIPTION ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfigJobDescription (docType: $docType, docConfigId: $docConfigId) {id}
}`
