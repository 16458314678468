import gql from 'graphql-tag'


const netPriceResponse = `
  id parentId childId options
  childId child {
    id type name options
    accountId account {id code name}
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
  }
`

export const LIST_DOC_CONFIG_EMPLOYEE_NET_PRICE = (templateType) => gql`query LIST_DOC_CONFIG_EMPLOYEE_NET_PRICE ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}DocConfigEmployeeNetPrice (docType: $docType, parentId: $parentId) {${netPriceResponse}}
}`
