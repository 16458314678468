import gql from 'graphql-tag'


export const LIST_ORDER_PROFILE = (templateType) => gql`query LIST_ORDER_PROFILE ($docType: String!, $docId: Int!, $q: FilterInput) {
  items: listDoc${templateType}OrderProfile (docType: $docType, docId: $docId, q: $q) {
    id type options
    inventoryId inventory {
      id type code name unitId
      unit {id name}
    }
  }
}`

export const CREATE_ORDER_PROFILE = (templateType) => gql`mutation CREATE_ORDER_PROFILE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  item: createDoc${templateType}OrderProfile (docType: $docType, docId: $docId, input: $input) {id}
}`
