import gql from 'graphql-tag'


const profileResponse = `
  id type docType name
  options parentId
  accountId account {
    id code name
  }
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
`

export const LIST_DOC_CONFIG_JOB_DESCRIPTION_PROFILE = (templateType) => gql`query LIST_DOC_CONFIG_JOB_DESCRIPTION_PROFILE ($docType: String!, $parentId: Int!, $types: [String!]) {
  items: listDoc${templateType}DocConfigJobDescriptionProfile (docType: $docType, parentId: $parentId, types: $types) {${profileResponse}}
}`

export const CREATE_DOC_CONFIG_JOB_DESCRIPTION_PROFILE = (templateType) => gql`mutation CREATE_DOC_CONFIG_JOB_DESCRIPTION_PROFILE ($docType: String!, $docConfigId: Int, $parentId: Int!, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfigJobDescriptionProfile (docType: $docType, docConfigId: $docConfigId, parentId: $parentId, input: $input) {${profileResponse}}
}`

export const DESTROY_DOC_CONFIG_JOB_DESCRIPTION_PROFILE = (templateType) => gql`mutation DESTROY_DOC_CONFIG_JOB_DESCRIPTION_PROFILE ($docType: String!, $parentId: Int!, $profileId: Int!) {
  item: destroyDoc${templateType}DocConfigJobDescriptionProfile (docType: $docType, parentId: $parentId, profileId: $profileId) {id}
}`
