import gql from 'graphql-tag'


const taxResponse = `
  id parentId childId options
  childId child {
    id type name
  }
`

export const LIST_DOC_CONFIG_JOB_DESCRIPTION_TAX = (templateType) => gql`query LIST_DOC_CONFIG_JOB_DESCRIPTION_TAX ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}DocConfigJobDescriptionTax (docType: $docType, parentId: $parentId) {${taxResponse}}
}`

export const CREATE_DOC_CONFIG_JOB_DESCRIPTION_TAX = (templateType) => gql`mutation CREATE_DOC_CONFIG_JOB_DESCRIPTION_TAX ($docType: String!, $parentId: Int!, $taxId: Int!) {
  item: createDoc${templateType}DocConfigJobDescriptionTax (docType: $docType, parentId: $parentId, taxId: $taxId) {
    id parentId childId
  }
}`

export const DESTROY_DOC_CONFIG_JOB_DESCRIPTION_TAX = (templateType) => gql`mutation DESTROY_DOC_CONFIG_JOB_DESCRIPTION_TAX ($docType: String!, $parentId: Int!, $taxId: Int!) {
  item: destroyDoc${templateType}DocConfigJobDescriptionTax (docType: $docType, parentId: $parentId, taxId: $taxId) {
    id parentId childId
  }
}`
