import gql from 'graphql-tag'


export const LIST_ORDER_TAX = (templateType) => gql`query LIST_ORDER_TAX ($docType: String!, $docId: Int!, $q: FilterInput) {
  items: listDoc${templateType}OrderTax (docType: $docType, docId: $docId, q: $q) {
    id type name
  }
}`

export const CREATE_ORDER_TAX = (templateType) => gql`mutation CREATE_ORDER_TAX ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  item: createDoc${templateType}OrderTax (docType: $docType, docId: $docId, input: $input) {id}
}`

export const DETAIL_ORDER_TAX_PRICE = (templateType) => gql`query DETAIL_ORDER_TAX_PRICE ($docType: String!, $docId: Int!, $docConfigId: Int!) {
  item: detailDoc${templateType}OrderTaxPrice (docType: $docType, docId: $docId, docConfigId: $docConfigId) {
    qty price calculations
  }
}`
