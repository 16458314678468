import gql from 'graphql-tag'


const netPriceResponse = `
  id parentId childId options
  childId child {
    id type name
  }
`

export const LIST_DOC_CONFIG_JOB_DESCRIPTION_NET_PRICE = (templateType) => gql`query LIST_DOC_CONFIG_JOB_DESCRIPTION_NET_PRICE ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}DocConfigJobDescriptionNetPrice (docType: $docType, parentId: $parentId) {${netPriceResponse}}
}`

export const CREATE_DOC_CONFIG_JOB_DESCRIPTION_NET_PRICE = (templateType) => gql`mutation CREATE_DOC_CONFIG_JOB_DESCRIPTION_NET_PRICE ($docType: String!, $parentId: Int!, $netPriceId: Int!) {
  item: createDoc${templateType}DocConfigJobDescriptionNetPrice (docType: $docType, parentId: $parentId, netPriceId: $netPriceId) {
    id parentId childId
  }
}`

export const DESTROY_DOC_CONFIG_JOB_DESCRIPTION_NET_PRICE = (templateType) => gql`mutation DESTROY_DOC_CONFIG_JOB_DESCRIPTION_NET_PRICE ($docType: String!, $parentId: Int!, $netPriceId: Int!) {
  item: destroyDoc${templateType}DocConfigJobDescriptionNetPrice (docType: $docType, parentId: $parentId, netPriceId: $netPriceId) {
    id parentId childId
  }
}`
