import gql from 'graphql-tag'


const listResponse = `
  id type docType name options
`

export const LIST_DOC_CONFIG_DISCOUNT = (templateType) => gql`query LIST_DOC_CONFIG_DISCOUNT ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDiscount (docType: $docType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType name options
`

export const DETAIL_DOC_CONFIG_DISCOUNT = (templateType) => gql`query DETAIL_DOC_CONFIG_DISCOUNT ($docType: String!, $docConfigId: Int!) {
  item: detailDoc${templateType}DocConfigDiscount (docType: $docType, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_DOC_CONFIG_DISCOUNT = (templateType) => gql`mutation CREATE_DOC_CONFIG_DISCOUNT ($docType: String!, $docConfigId: Int, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfigDiscount (docType: $docType, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_DOC_CONFIG_DISCOUNT = (templateType) => gql`mutation DESTROY_DOC_CONFIG_DISCOUNT ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfigDiscount (docType: $docType, docConfigId: $docConfigId) {id}
}`
