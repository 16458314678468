import gql from 'graphql-tag'


const socialSecurityResponse = `
  id parentId childId options
  childId child {
    id type name options
    accountId account {id code name}
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
  }
`

export const LIST_DOC_CONFIG_EMPLOYEE_SOCIAL_SECURITY = (templateType) => gql`query LIST_DOC_CONFIG_EMPLOYEE_SOCIAL_SECURITY ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}DocConfigEmployeeSocialSecurity (docType: $docType, parentId: $parentId) {${socialSecurityResponse}}
}`
