import gql from 'graphql-tag'


const socialSecurityResponse = `
  id parentId childId options
  childId child {
    id type name
  }
`

export const LIST_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY = (templateType) => gql`query LIST_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}DocConfigJobDescriptionSocialSecurity (docType: $docType, parentId: $parentId) {${socialSecurityResponse}}
}`

export const CREATE_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY = (templateType) => gql`mutation CREATE_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY ($docType: String!, $parentId: Int!, $socialSecurityId: Int!) {
  item: createDoc${templateType}DocConfigJobDescriptionSocialSecurity (docType: $docType, parentId: $parentId, socialSecurityId: $socialSecurityId) {
    id parentId childId
  }
}`

export const DESTROY_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY = (templateType) => gql`mutation DESTROY_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY ($docType: String!, $parentId: Int!, $socialSecurityId: Int!) {
  item: destroyDoc${templateType}DocConfigJobDescriptionSocialSecurity (docType: $docType, parentId: $parentId, socialSecurityId: $socialSecurityId) {
    id parentId childId
  }
}`
